const state = {
  drawer: localStorage.getItem('drawer') ?  localStorage.getItem('drawer') : true
}

const getters = {
  getDrawer: state => {
    return state.drawer
  }
}

const actions = {
  setDrawer ({ commit }, data) {
    localStorage.setItem('drawer',data)
    commit('setDrawer', data)
  }
}

const mutations = {
  setDrawer (state, data) {
    state.drawer = data
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
