import Vue from 'vue'
import Vuex from 'vuex'

import MenuStore from './modules/MenuStore'
import SnackbarStore from './modules/SnackbarStore'
import UserStore from './modules/UserStore'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    MenuStore,
    SnackbarStore,
    UserStore
  },
});
