const state = {
  user: localStorage.getItem('user') !== 'undefined' ? JSON.parse(localStorage.getItem('user')) : null,
  token: localStorage.getItem('token') ? localStorage.getItem('token') : null
}

const getters = {
  user: (state) => {
    return state.user
  },
  tokenValido: (state, getters) => {
    if (getters.payloadToken) {
      if (getters.payloadToken.data_expiracao) {
        const date = Math.floor(Date.now() / 1000)
        const expDate = Math.floor(new Date(getters.payloadToken.data_expiracao) / 1000)
        if (expDate > date) return true
      }
    }
    return false
  },
  payloadToken: state => {
    if (state.token) {
      const partesToken = state.token.split('.')

      if (partesToken.length === 3) {
        try {
          return JSON.parse(atob(partesToken[1]))
        } catch (e) {
          return null
        }
      }
    }
    return null
  }
}

const actions = {
  autenticar ({commit, dispatch}, token) {
    dispatch('limparDadosSessao')
    commit('gravarDadosAutenticacao', token)
    dispatch('gravarDadosAutenticacaoSessao')
  },
  limparDadosSessao () {
    localStorage.removeItem('token')
    localStorage.removeItem('user')
    localStorage.removeItem('routePath')
  },
  gravarDadosAutenticacaoSessao ({state}) {
    localStorage.setItem('user', JSON.stringify(state.user))
    localStorage.setItem('token', state.token)
  },
  logout ({dispatch, commit}) {
    dispatch('limparDadosSessao')
    commit('limparSessao')
  }
}

const mutations = {
  gravarDadosAutenticacao (state, token) {
    state.token = token
    let user
    let partesToken = state.token.split('.')

    if (partesToken.length === 3) {
      try {
        user = JSON.parse(atob(partesToken[1])).usuario
      } catch (e) {
        user = null
      }
    }

    state.user = user
  },
  limparSessao (state) {
    state.user = null
    state.token = null
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
