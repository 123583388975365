import Vue from 'vue'
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

export default new Vuetify({
  iconfont: 'mdi',
  theme: {
    themes: {
      light: {
        primary: '#255c7d',
        secondary: '#2d7040',
        tertiary: '#96d23c',
        accent: '#50d5f7',
        light: '#ffffff',
        primaryDark: '#4c5469',
        success: '#009688',
        error: '#B71C1C',
        muted: '#E1E2E1',
      },
    },
  },
  lang: {
    current: 'pt',
  },
});
