<template>
  <div class="text-center">
    <v-snackbar
      v-model="snackbar"
      :color="type"
      light
      :multi-line="true"
      class="ma-2 snackbar"
      :class="'snackbar-' + type"
      dark
      right 
      top
    >
    <v-row align="center" justify="center">
      <v-col class="pa-0 pl-4">
        <span>{{ text }}</span>
      </v-col>
      <v-col class="pa-0" cols="auto">
        <v-btn text @click="close()">
          <v-icon>close</v-icon> 
        </v-btn>
      </v-col>
    </v-row>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: 'AppSnackbar',
  computed: {
    snackbar: {
      get () {
        return this.$store.getters['SnackbarStore/snackbar']
      },
      set () {
        this.close()
      }  
    },
    text () {
      return this.$store.getters['SnackbarStore/text']
    },
    type () {
      return this.$store.getters['SnackbarStore/type']
    }
  },
  methods: {
    close () {
      this.$store.dispatch('SnackbarStore/hideSnackbar').then(() => {})
    }
  }
}
</script>

<style lang="scss">
@import '@/scss/theme.scss';
.snackbar-accent {
  .v-snack__content,
  .v-snack__content .v-btn--text {
    color: #242424 !important;
  }
}
.snackbar span { font-size: 1rem; color: #fff; }
.snackbar-error { color: $error; }
.snackbar-success { color: $success; }
</style>