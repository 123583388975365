import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import Axios from './plugins/axios'
import store from './store'
import VueMask from 'v-mask'
import VueCarousel from 'vue-carousel'

Vue.use(VueCarousel)
Vue.use(VueMask)

Vue.config.productionTip = false
Vue.prototype.$axios = Axios
Vue.prototype.$API_URL = process.env.VUE_APP_URL_API
// Vue.prototype.$API_URL = 'https://api.4-trace.com.br/public/api'

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
