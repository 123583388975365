import axios from 'axios';

//const base = 'http://localhost:8000/api'
const  base = process.env.APP_VUE_URL_API
// const base = 'https://api.4-trace.com.br/public/api'

const Axios = axios.create({
  baseURL: base,
  headers: {
    'Content-Type': 'application/json',
  }
});

Axios.interceptors.request.use(
  function (config) {
    config.headers.token = localStorage.token ?? null;
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (401 === error.response.status) {
      window.location.href = 'logout';
    } else return Promise.reject(error);
  }
);

export default Axios;
