const routeEnum = {
  MOVIMENTATION: 'movimentations',
  AUTH: 'auth',
  LOGOUT: 'logout',
  DASHBOARD: 'dashboard',
  CATEGORY: 'categories',
  EQUIPAMENT: 'equipaments',
  EQUIPAMENT_DETAIL: 'equipament-detail',
  MANUFACTURER: 'manufacturers',
  UNIT_HISTORIC: 'unit-historic',
  PLACE: 'places',
  PROVIDER: 'providers',
  REPORT: 'report',
  SETTING: 'settings',
  USER: 'users'
}

export default routeEnum