import routeEnum from '@/modules/shared/enums/RouteEnum'
import Vue from 'vue'
import VueRouter from 'vue-router'

const AuthView = () => import('../views/AuthView.vue')
const CategoriesView = () => import('../views/CategoriesView.vue')
const DashboardView = () => import('../views/DashboardView.vue')
const EquipamentsView = () => import('../views/EquipamentsView.vue')
const EquipamentDetailView = () => import('../views/EquipamentDetailView.vue')
const HistoricView = () => import('../views/HistoricView.vue')
const LogoutView = () => import('../views/LogoutView.vue')
const ManufacturersView = () => import('../views/ManufacturersView.vue')
const MovimentationsView = () => import('../views/MovimentationsView.vue')
const PlacesView = () => import('../views/PlacesView.vue')
const ProvidersView = () => import('../views/ProvidersView.vue')
const PortalTemplate = () => import('../components/PortalTemplate.vue')
const ReportView = () => import('../views/ReportView.vue')
const SearchView = () => import('../views/SearchView.vue')
const SettingsView = () => import('../views/SettingsView.vue')
const UsersView = () => import('../views/UsersView.vue')

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: routeEnum.AUTH,
    component: AuthView
  },
  {
    path: '/logout',
    name: routeEnum.LOGOUT,
    component: LogoutView
  },
  {
    path: '/',
    component: PortalTemplate,
    children: [
      {
        path: '',
        redirect: { name: routeEnum.DASHBOARD }
      },
      {
        path: '/categorias',
        name: routeEnum.CATEGORY,
        component: CategoriesView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.CATEGORY
        },
      },
      {
        path: '/dashboard',
        name: routeEnum.DASHBOARD,
        component: DashboardView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.DASHBOARD
        },
      },
      {
        path: '/equipamentos',
        name: routeEnum.EQUIPAMENT,
        component: EquipamentsView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.EQUIPAMENT
        },
      },
      {
        path: '/equipamento/:id',
        name: routeEnum.EQUIPAMENT_DETAIL,
        component: EquipamentDetailView,
        meta: {
          requiresAuth: true,
          backButtonRoute: {
            name: routeEnum.EQUIPAMENT,
            params: null
          },
          menu: routeEnum.EQUIPAMENT
        },
      },
      {
        path: '/fabricantes',
        name: routeEnum.MANUFACTURER,
        component: ManufacturersView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.MANUFACTURER
        },
      },
      {
        path: '/movimentacoes',
        name: routeEnum.MOVIMENTATION,
        component: MovimentationsView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.MOVIMENTATION
        },
      },
      {
        path: '/equipamento/:equipamentId/unit/:unitId/rastreio',
        name: routeEnum.UNIT_HISTORIC,
        component: HistoricView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.EQUIPAMENT,
          backButtonRoute: {
            name: routeEnum.EQUIPAMENT_DETAIL,
            params: null
          },
        },
      },
      {
        path: '/locais',
        name: routeEnum.PLACE,
        component: PlacesView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.PLACE
        },
      },
      {
        path: '/fornecedores',
        name: routeEnum.PROVIDER,
        component: ProvidersView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.PROVIDER
        },
      },
      {
        path: '/configuracoes',
        name: routeEnum.SETTING,
        component: SettingsView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.SETTING
        },
      },
      {
        path: '/usuarios',
        name: routeEnum.USER,
        component: UsersView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.USER
        },
      },
      {
        path: '/pesquisa',
        name: 'search',
        component: SearchView,
        meta: {
          requiresAuth: true,
          menu: null
        },
      },
      {
        path: '/relatorio',
        name: routeEnum.REPORT,
        component: ReportView,
        meta: {
          requiresAuth: true,
          menu: routeEnum.REPORT
        },
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
	document.title = to.meta.pageName || '4 Trace'
	localStorage.setItem('lastUrl', to.path)

  let tokenValidado = false

	if (to.meta.requiresAuth) {
		let token = localStorage.token ?? null
		
		if (token) {
			let partesToken = token.split('.')

			if (partesToken.length === 3) {
				try {
					let payloadToken = JSON.parse(atob(partesToken[1]))
					let date = Math.floor(Date.now() / 1000)
					let expDate = Math.floor(new Date(payloadToken.data_expiracao) / 1000)
					if (expDate > date) tokenValidado = true			
				} catch (_) { 
          tokenValidado = false 
        }
			}
		}
	} else tokenValidado = true

	if (tokenValidado) {
		next()
	} else {
		window.location = `login`
	}
})

export default router
